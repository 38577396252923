import TextScroller from "./modules/TextScroller";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {
    const scroller = new TextScroller('.schools__address');
    scroller.init();
  }
})();
