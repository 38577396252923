export default class TextScroller {
  constructor(selector) {
    this.items = document.querySelectorAll(selector);
    this.mobile = null;
  }

  init() {
    document.addEventListener('scroll', this._handleScrollText.bind(this));
  }

  _handleScrollText(e) {
    this.items.forEach((el) => {
      if (this._elementIsInViewport(el)) {
        let currentPosition = 0;

        const style = window.getComputedStyle(el.children[0]);
        const matrix = style.transform;
        const matrixType = matrix.includes('3d') ? '3d' : '2d';
        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');
        if (matrixType === '2d') {
          currentPosition = +matrixValues[4];
        }

        const scale = +el.getAttribute('data-scale');
        const direction = el.getAttribute('data-direction');
        let translate;

        if (direction === 'left') {
          translate = this._lastScrollPosition >= window.scrollY ? currentPosition + scale : currentPosition - scale;
        } else {
          translate = this._lastScrollPosition >= window.scrollY ? currentPosition - scale : currentPosition + scale;
        }

        el.children[0].style.transform = `translate3d(${translate}px, 0, 0)`;
      }
    });

    this._lastScrollPosition = window.scrollY;
  }

  _elementIsInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      (rect.top <= 0
        && rect.bottom >= 0)
      ||
      (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight))
      ||
      (rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    );
  }
}
